import { graphql } from "gatsby"
import React from "react"
import { Container, Col, Row, Image, Figure } from "react-bootstrap"

import Layout from "../../components/layoutHome"
import SEO from "../../components/seo"

import {Typography,Paper,Grid} from '@material-ui/core'
import { makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  Title:{
          width:"100%",
          display:"flex",
          justifyContent:"Center",
          //margin:"30px",
          height:"30px",
          fontFamily: 'Montserrat',
          fontWeight: 'bold',
          background: "rgba(71,  0,  0, 0.8)",
          color:"white",
          fontSize:"28px",
          alignItems:"center"
        },
        SubTitle:{
          width:"100%",
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
          marginBottom:"10px",
          textAlign:"justify",
          background: "rgba(71,  0,  0, 0.8)",
          color:"white",
          
        },
        imgContainer:{
            padding:"5px",
            width:"100%",
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
  
        },
        imgText:{
            textAlign:"center",
  
        },
        img:{
            border:"black",
            borderStyle:"groove"
        },
        GridContainer:{
            margin:"10px"
        },
        EventContainer:{
          margin:"10px",
          width:"100%"
        },
        TextEvent:{
          width:"100%",
          padding:"10px",
          textAlign:"justify"
        },
        Seccion:{
            marginTop:"10px",
            marginBottom:"10px",
            paddingLeft:"10px",
            paddingRight:"10px"
        },
        espacio:{
            padding:"5px"
        },
        full:{
            width:"100%"
        },
        Principal:{
          maxHeight:"550px",
          maxWidth:"100%",
          padding:"10px",
        }
  
    }));
  

const Servicios = ({ data }) => {
  const Model = data.ceformaPagesShow6.data.page.content
  const classes = useStyles(); 
  return (
    <Layout>
      <SEO title="Servicios" />
      <Container>
        <Row>
          <h1>{Model.pageTitle}</h1>
        </Row>
        <Row>
          <Col>
            <ul style={{ columns: 2 }}>
              {Model.Services.map(service => (
                <li>{service.Servicio}</li>
              ))}
            </ul>
          </Col>
        </Row>

           <Grid container >
           {Model.Imgs.map(img => (
            <Grid iteam xs={12} sm={6} md={4} lg={4} xl={4}
            className={classes.espacio}
            >
              <Paper className={classes.imgContainer}>
              <Figure>
                <Figure.Image
                  width={"100%"}
                  height={231}
                  alt={img.ImgDescripcion}
                  src={img.ImgUrl}
                  className={classes.img}
                  />
                <Figure.Caption className={classes.imgText}>
                  {img.ImgDescripcion}
                </Figure.Caption>
                </Figure>
                </Paper>
            </Grid>
          ))} 
             </Grid>     
          

      </Container>
    </Layout>
  )
}

export default Servicios

export const query = graphql`
  query ServicesQuery {
    ceformaPagesShow6 {
      data {
        page {
          content {
            Imgs {
              ImgUrl
              ImgDescripcion
            }
            Services {
              Servicio
            }
            pageTitle
            descriptiveText
          }
        }
      }
    }
  }
`
